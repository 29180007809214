import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { AppMaterialModule } from './app-material-module';
import { CommonComponentsModule } from './common-components/common-components.module';
import { CommonServiceModule } from './common-service/common-service.module';
// Import from Angular Materials:
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';

import { AppErrorHandlerService } from './common-service/app-error-handler.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MdlModule} from '@angular-mdl/core';
import { TourMatMenuModule } from 'ngx-tour-md-menu';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AppMaterialModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MdlModule,
    AuthModule,
    CommonServiceModule,
    CommonComponentsModule,
    TourMatMenuModule.forRoot()
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
