import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from './../app-material-module';
import { CommonComponentsModule } from './../common-components/common-components.module';
import { Routes, RouterModule } from '@angular/router';


import { AuthOverlayComponent } from './auth-overlay/auth-overlay.component';
import { AuthControlComponent } from './auth-control/auth-control.component';
import { AuthProfileButtonComponent } from './auth-profile-button/auth-profile-button.component';
import { AppUiModule } from '../app-ui/app-ui.module';


@NgModule({
  imports: [
    CommonModule,
    AppMaterialModule,
    CommonComponentsModule,
    AppUiModule,
    RouterModule,
  ],
  declarations: [AuthOverlayComponent, AuthControlComponent, AuthProfileButtonComponent],
  entryComponents: [AuthOverlayComponent],
  exports: [AuthControlComponent]
})
export class AuthModule { }
