'use strict';

/* This will have the states as key and events as subobject */

module.exports = {
    'created': {
        'acceptbyretailer': {
            'displayName': 'Accept',
            'nextState': 'final',
            'copyAttributes': [],
            'isServerOnly': false,
            'checkPermission': (_obj, _jwt) => {
                return _obj.to_parent == _jwt.seller;
            }
        },
        'editbysupplier': {
            'displayName': 'Edit',
            'nextState': 'created',
            'copyAttributes': [
                'header_json',
                'items',
                'total',
                'transaction_json',
                'transaction_rule'],
            'isServerOnly': false,
            'checkPermission': (_obj, _jwt) => {
                return _obj.from_parent == _jwt.seller;
            }
        },
        'deletebysupplier': {
            'displayName': 'Delete',
            'nextState': 'deleted',
            'copyAttributes': [],
            'isServerOnly': false,
            'checkPermission': (_obj, _jwt) => {
                return _obj.from_parent == _jwt.seller;
            }
        }
    },

    'deleted': {

    },
    'final': {
        'payment_initiate': {
            'displayName': null,
            'nextState': 'paymentinprogress',
            'copyAttributes': [],
            'isServerOnly': true,
            'checkPermission': (_obj, _jwt) => {
                return true;
            }
        },
        'adjustment_initiate': {
            'displayName': null,
            'nextState': 'adjustmentinprogress',
            'copyAttributes': [],
            'isServerOnly': true,
            'checkPermission': (_obj, _jwt) => {
                return true;
            }
        }
    },
    'paymentinprogress': {
        'payment_complete': {
            'displayName': null,
            'nextState': 'paid',
            'copyAttributes': [],
            'isServerOnly': true,
            'checkPermission': (_obj, _jwt) => {
                return true;
            }
        },
        'payment_deleted': {
            'displayName': null,
            'nextState': 'final',
            'copyAttributes': [],
            'isServerOnly': true,
            'checkPermission': (_obj, _jwt) => {
                return true;
            }
        }
    },
    'adjustmentinprogress': {
        'adjustment_complete': {
            'displayName': null,
            'nextState': 'paid',
            'copyAttributes': [],
            'isServerOnly': true,
            'checkPermission': (_obj, _jwt) => {
                return true;
            }
        },
        'adjustment_deleted': {
            'displayName': null,
            'nextState': 'final',
            'copyAttributes': [],
            'isServerOnly': true,
            'checkPermission': (_obj, _jwt) => {
                return true;
            }
        }
    },
    'paid': {

    }

};
