import { Injectable } from '@angular/core';
@Injectable({
    providedIn: 'root'
})
export class UtilityService {


    constructor() { }

    get_time_int(uuid_str) {
        var uuid_arr = uuid_str.split('-'),
            time_str = [
                uuid_arr[2].substring(1),
                uuid_arr[1],
                uuid_arr[0]
            ].join('');
        return parseInt(time_str, 16);
    };

    get_date_obj(uuid_str) {
        var int_time = this.get_time_int(uuid_str) - 122192928000000000,
            int_millisec = Math.floor(int_time / 10000);
        return new Date(int_millisec);
    };

}
