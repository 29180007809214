import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { Seller } from './../../../models/seller.model';
import { SellerViewModel } from './../seller-view-model';

import { AuthService } from './../../common-service/auth.service';
import _ from 'lodash';

@Component({
  selector: 'app-seller-form',
  templateUrl: './seller-form.component.html',
  styleUrls: ['./seller-form.component.scss']
})
export class SellerFormComponent implements OnInit {

  @Input() sellerFormGroup: FormGroup;
  @Input() seller: SellerViewModel;
  comm_agent_email: string;
  all_permissions: any;
  constructor(private authService: AuthService,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.all_permissions = SellerViewModel.all_permissions;
  }

  onAddEmp() {
    this.authService.getUserByEmail(this.sellerFormGroup.value.comm_agent_email).subscribe(
      employee => {
        if (employee) {
          const agent = {
            _id: employee._id,
            name: employee.name,
            email: employee.email,
            permissions: this.all_permissions
          };
          const employees = this.sellerFormGroup.get('employees') as FormArray;
          employees.push(this.createEmployeeFormGroup(agent));
          this.sellerFormGroup.get('comm_agent_email').patchValue = null;
        }
      },
      error => {
        console.log(error, 'No user with this email');
      }
    );

  }
  onRemoveEmp(idx) {
      const employees = this.sellerFormGroup.get('employees') as FormArray;
      employees.removeAt(idx);
  }
  createEmployeesFormArray(employees: any): FormArray {
    const employeesFormArray: FormArray = new FormArray([]);
    _.map(employees, employee => {
      employeesFormArray.push(this.createEmployeeFormGroup(employee));
    });
    return employeesFormArray;
  }
  createEmployeeFormGroup(employee: any): FormGroup {
    return this.formBuilder.group({
      _id: [employee._id],
      name: [employee.name],
      email: [employee.email],
      permissions: this.createPermissionsFormArray(employee.permissions),
    });
  }

  createPermissionsFormArray(permissions: any): FormArray {
    const PermissionsFormArray: FormArray = new FormArray([]);
    _.map(permissions, permission => {
      PermissionsFormArray.push(this.createPermissionFormGroup(permission));
    });
    return PermissionsFormArray;
  }
  createPermissionFormGroup(permission: any): FormGroup {
    return this.formBuilder.group({
      label: [permission.label],
      value: [permission.value],
    });
  }
}
