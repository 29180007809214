import {Component, Inject} from '@angular/core';
import { Router } from '@angular/router';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import { AuthService } from './../../common-service/auth.service';
import { TranService } from './../tran.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ItemBatchDialogComponent } from './../item-batch-dialog/item-batch-dialog.component';

@Component({
  selector: 'app-show-stock-view-dialog',
  template: `<button mat-button (click)="openDialog()">
                  <i class="fa fa-battery-three-quarters" aria-hidden="true"></i> Stock
             </button>`
})

export class ShowStockViewDialogComponent {
  selectedOption: string;

  constructor(public dialog: MatDialog) {}

  openDialog() {
    const dialogRef = this.dialog.open(StockViewDialogComponent, {
        position: { top: '70px' , left: 'calc(100% - 720px)'}
    });
  }
}


@Component({
  selector: 'app-stock-view-dialog',
  templateUrl: './stock-view.component.html',
})
export class StockViewDialogComponent {
  errorMessage: any;
  searchParam: any = {ItemName: null, ItemId: null};
  stock = [];
  total_stock = 0;
  idx = 0;
  constructor(public dialogRef: MatDialogRef<ShowStockViewDialogComponent>,
        public router: Router,
        public authService: AuthService,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public tranService: TranService) {
            if (data) {
                this.searchParam.ItemName = data.item_name;
                this.searchParam.ItemId = data.item;
            }
        }
        public itemSelected($event, idx): void {
            if ($event._id) {
                this.getStock( $event._id);
            }
        }

        getTransactionsForBatch(item, batch_) {
            this.tranService.getTransactionsForBatch(item, batch_).subscribe(res => {
                console.log(res);
                this.dialog.
                    open(ItemBatchDialogComponent,
                    {
                        width: '800px',
                        position: { top: '70px' , left: 'calc(100% - 1420px)'},
                        data: {
                            batch: batch_,
                            trans: res
                        }
                    });
            }, err => {
                console.log(err);
            });
        }
        getStock(item_id) {
            this.tranService.getStock(item_id).subscribe(
                stock => {
                    this.total_stock = 0;
                    this.stock[0] = _.chain(stock)
                        .transform((result, value, key) => {
                            let exp = 'Jan-2017';
                            exp = moment(JSON.parse(value.item_json).expiry,
                            ['YYYY-MM', 'YYYY-MM-DD', 'MM/YYYY', 'DD/MM/YYYY', 'MM/DD/YYYY', 'MM/YY', 'DD/MM/YY', 'MM/DD/YY',
                             moment.ISO_8601]).format('MMM-YY');

                            if (exp === 'Invalid date') {
                                exp = 'Jan-2017';
                            }
                            this.total_stock += value.stock;
                            result.push({
                                item: item_id,
                                'batch': key,
                                'stock': value.stock,
                                'expiry': exp,
                                'mrp': JSON.parse(value.item_json).mrp, 'mrp_per_pcs': JSON.parse(value.item_json).mrp_per_pcs,
                                'rate': JSON.parse(value.item_json).rate, 'rate_per_pcs': JSON.parse(value.item_json).rate_per_pcs,
                                'pack': JSON.parse(value.item_json).pack,
                                'cgst': JSON.parse(value.item_json).cgst, 'sgst': JSON.parse(value.item_json).sgst });
                        }, []).value();
                },
                err => { throw err; }
            );

        }
    }
