import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { Seller } from './../../../models/seller.model';
import { SellerViewModel } from './../seller-view-model';
import { PartyService } from './../../common-service/party.service';
import { TranService } from './../../transaction/tran.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
@Component({
  selector: 'app-party-dialog',
  templateUrl: './party-dialog.component.html',
  styleUrls: ['./party-dialog.component.scss']
})
export class PartyDialogComponent implements OnInit {

    public partyFormGroup: FormGroup;
    private party: SellerViewModel;

    constructor(private formBuilder: FormBuilder,
                private partyService: PartyService,
                private tranService: TranService,
                public dialogRef: MatDialogRef<PartyDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private router: Router) { }

    ngOnInit() {
        this.party = SellerViewModel.create();
        if (this.data && this.data.create) {
            if (this.data['from_or_to'] === 'from') {
                if (this.data['is_return'] === 'return') {
                    this.party.party_category = 'supplier';
                } else {
                    this.party.party_category = 'customer';
                }
            } else {
                if (this.data['is_return'] === 'return') {
                    this.party.party_category = 'customer';
                } else {
                    this.party.party_category = 'supplier';
                }
            }
            this.party.name = this.data['party_name'];
        }
        this.partyFormGroup = this.formBuilder.group({
            name: [this.party.name, Validators.required],
            licence: [this.party.licence],
            gstin: [this.party.gstin],
            primary_address: this.formBuilder.group({
                street: [this.party.primary_address.street, Validators.required],
                city: [this.party.primary_address.city],
                state: [this.party.primary_address.state],
                zip: [this.party.primary_address.zip],
                landmark: [this.party.primary_address.landmark]
            }),
            mobile: [this.party.mobile],
            landline: [this.party.landline],
            party_category: [this.party.party_category, Validators.required],
            location: [this.party.location],
        });
        console.log(this.partyFormGroup);
    }
    cancel() {
        this.dialogRef.close('cancel');
    }
    create(form: FormGroup) {
        this.partyService.create(SellerViewModel.create(form.value, false)).subscribe(
            (party) => {
                console.log(party);
                if (this.data && this.data['create']) {
                    if (this.data['from_or_to'] === 'from') {
                        this.tranService.transaction.to_ = party._id;
                        this.tranService.transaction.to_name = party.name;
                    } else {
                        this.tranService.transaction.from_ = party._id;
                        this.tranService.transaction.from_name = party.name;
                    }
                    this.dialogRef.close('saved');
                //    this._location.back();
                }
            },
            (error) => {console.log(error);
            }
        );
    }
}
