import {Component, OnInit, Inject} from '@angular/core';
import {Location} from '@angular/common';
import {Router, ActivatedRoute} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {AuthService} from './../../common-service/auth.service';
import {OrderService} from './../order.service';
import * as _ from 'lodash';

@Component({
    selector: 'app-inv-order',
    styleUrls: ['./inv-order.component.scss'],
    templateUrl: './inv-order.component.html'
})
export class InvOrderComponent implements OnInit {
    order: any = {
        to_: '',
        to_name: '',
        from_: '',
        from_name: '',
        item: '',
        item_name: '',
        quantity: null,
    };
    from_or_to: string;
    constructor(public orderService: OrderService,
        public authService: AuthService,
        public _location: Location,
        public activatedRoute: ActivatedRoute,
        public router: Router,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public mdDialogRef: MatDialogRef<InvOrderComponent>

    ) {
    }

    ngOnInit() {
        //    this.orderService.create = true;
        if (this.data) {
            this.from_or_to = this.data.from_or_to;
            _.assign(this.order, this.data.order);
        } else {
            this.from_or_to = this.activatedRoute.snapshot.url[1].path;
        }

    }

    toLocal(date) {
        const local = new Date(date);
        local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return local;
    }

    createOrder(order: any, from_or_to: string) {
        this.orderService.createOrder(order, from_or_to).subscribe(
            order1 => {
                //      this.orderService.orders.push(order)
                this.orderService.idx = 0;
                //        this.orderService.create = false;
                if (this.data) {
                  this.mdDialogRef.close();
                } else {
                  this._location.back();
                }
                // this.router.navigate(['/container-order']);
            },
            err => this.orderService.errMsg = err
        );
    }

    onConfirm(action, order) {
        if (action === 'confirm') {
            if (order.item) {
            //    if (this.from_or_to == 'from') {
                    order.from_ = this.authService.getUser().current_seller;
            //    } else {
            //        order.to_ = this.authService.getUser().current_seller;
            //    }

                order.previous_action = 'order ceated';
                this.createOrder(order, this.from_or_to);
            }
        } else {
            //        this.orderService.create = false;
            if (this.data) {
              this.mdDialogRef.close();
            } else {
              this._location.back();
            }
            // this.router.navigate(['/container-order']);
        }
    }
}
