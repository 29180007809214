import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit, OnChanges {
  /**
   * The full name of the user for the avatar letter
   * @type {string}
   */
  @Input('name') name: string;

  /**
   * The display size
   * @type {number}
   */
  @Input('size') size = 100;

  /**
   * Value to set a fixed color via HEX code
   * @type {string}
   */
  @Input('background') background = this.getRandomColor();

  /**
   * Value to set the display type
   * @type {string} - none|circle|rounded
   */
  @Input('displayType') displayType = 'none';

  /**
   * Value to set a default letter
   * @type {string}
   */
  @Input('letter') letter = '?';

  fontSize = 49;
  fontColor = '#FFFFFF';
  props: any = null;
  constructor() { }

  ngOnInit() {
      this.setCssProps();

      this.getLetter();
  }

  ngOnChanges() {
      this.setCssProps();
      this.getLetter();
  }

  /**
   * Randomly generates a HEX color
   * @return {string}
   */
  getRandomColor(): string {
    const letters = '0123456789ABCDEF'.split('');
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  /**
  * Set the avatar letter based on full name or email
  */
  getLetter(): void {
    if (this.name && this.name.length) {
      const nameInitials = this.name.match(/\b(\w)/g);
      if (nameInitials) {
        const nameLetters = nameInitials.slice(0, 3).join('');
        this.letter = nameLetters.toUpperCase();
      } else {
        this.letter = this.name[0];
      }
    }
  }

  setCssProps() {
    this.fontSize = (39 * this.size) / 100;
    this.props = {
      size: `${this.size}px`,
      lineheight: `${this.size}px`,
      background: this.background,
      fontSize: `${this.fontSize}px`
    };

    switch (this.displayType) {
      case 'rounded':
        this.props['borderradius'] = '5%';
        break;
      case 'circle':
        this.props['borderradius'] = '50%';
        break;
      default:
        this.props['borderradius'] = '0';
    }

  }
}
