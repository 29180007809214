import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
// import 'rxjs'


@Component({
    selector: 'app-confirm-action',
    styleUrls: ['confirm-action.component.css'],
    templateUrl: 'confirm-action.component.html',
})

export class ConfirmActionComponent {

    @Input() comfirmDisabled = false;
    @Output() confirm = new EventEmitter<string>();



    private clickStream: Subject<any>;
    constructor() {
        this.clickStream = new Subject<any>();
        this.clickStream.pipe(throttleTime(5000)).subscribe( action => this.confirm.emit(action));
    }

    onConfirm(action) {
        this.clickStream.next(action);
    }

}
