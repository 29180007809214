import {HttpClient} from '@angular/common/http';
import { Observable, Subject, of } from 'rxjs';
import { debounceTime, switchMap, distinctUntilChanged, map } from 'rxjs/operators';
import { Component, Input, Output, EventEmitter, ViewChild, OnInit, forwardRef , OnChanges, SimpleChange} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'app-item-search',
    templateUrl: './item-search.component.html',
    styleUrls: ['./item-search.component.scss'],

})

export class ItemSearchComponent implements OnInit, OnChanges {
    private items_url = '/v1/manage/brand/listbybrandname/';
    private searchTermStream = new Subject<string>();
    @Input() private selectedItem: any;
    @Input() private enabled = true;
    @Output() private selectItem = new EventEmitter();

    constructor(private http: HttpClient) {

    }

    myControl = new FormControl();
    filteredOptions: any;


    ngOnInit() {
        this.myControl.setValue(this.selectedItem);
        this.enabled ? this.myControl.enable() : this.myControl.disable();
        this.selectItem.emit(this.selectedItem);
        this.filteredOptions = this.myControl.valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            switchMap((term: string) => {

                if (term.length >= 1) {
                    return this.getItems(term);
                 } else {
                    this.selectItem.emit(term);
                    return of([]);
                 }
             }));
    }
    transform_function(term) {
        try {
            return term.match(/[a-zA-Z0-9]/g)
            .join('')
            .toLowerCase();
        } catch (e) {
            return '';
    //        console.log(e);

        }
    }
    displayFn(item: any): string {
       if (item) {
           item._id = this.transform_function(item.name);
       } else {
           item._id = null;
       }
       this.selectItem.emit(item);
       return item ? item.name : item;
   }
   ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
       this.enabled ? this.myControl.enable() : this.myControl.disable();
       const log: string[] = [];
       for (const propName of Object.keys(changes)) {
           const changedProp = changes[propName];
       }
   }
   getItems(term: string) {
       this.displayFn({name: term});
       return this.http.get(this.items_url + encodeURIComponent(term)).pipe(map(res => res));
   }

}
