import {Component, OnInit, Inject} from '@angular/core';
import {Location} from '@angular/common';
import {Router, ActivatedRoute} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {AuthService} from './../../common-service/auth.service';
import {OrderService} from './../order.service';


@Component({
    selector: 'app-order-list-dialog',
    styleUrls: ['./order-list-dialog.component.scss'],
    templateUrl: './order-list-dialog.component.html'
})

export class OrderListDialogComponent implements OnInit {
    orders: any;
    from_or_to: string;
    constructor(public orderService: OrderService,
        public authService: AuthService,
        public _location: Location,
        public activatedRoute: ActivatedRoute,
        public router: Router,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public mdDialogRef: MatDialogRef<OrderListDialogComponent>

    ) {
    }

    ngOnInit() {
        //    this.orderService.create = true;
        if (this.data) {
            this.from_or_to = this.data.from_or_to;
          } else {
            this.from_or_to = this.activatedRoute.snapshot.url[1].path;
        }

    }

    toLocal(date) {
        const local = new Date(date);
        local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return local;
    }


}
