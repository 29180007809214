import { Seller } from '../../models/seller.model';
import { User } from '../../models/user.model';
import * as _ from 'lodash';

export class SellerViewModel {

    public static all_permissions: any[] = [
        { 'label': 'party', 'value': false },
        { 'label': 'order', 'value': false },
        { 'label': 'billing', 'value': false },
        { 'label': 'payment', 'value': false },
        { 'label': 'operating_reports', 'value': false },
        { 'label': 'confidential_reports', 'value': false },
    ];

    public employees: {
        _id?: string,
        name?: string,
        email?: string,
        user?: string,
        permissions: {
            label: string;
            value: boolean;
        }[];
    }[];
    public _id: string;
    public gstin: string;
    public landline: string;
    public licence: string;
    public long_description: string;
    public mobile: string;
    public name: string;
    public party_category: string;
    public primary_address: AddressViewModel;
    public shop_category: string;
    public short_description: string;
    public user: string;
    public location: any;

    static create(seller?: Seller, fromServer?: boolean): SellerViewModel {

        const sellerVM = new SellerViewModel();
        if (seller) {
            sellerVM._id = seller._id;
            sellerVM.gstin = seller.gstin;
            sellerVM.landline = seller.landline;
            sellerVM.licence = seller.licence;
            sellerVM.long_description = seller.long_description;
            sellerVM.mobile = seller.mobile;
            sellerVM.name = seller.name;
            sellerVM.shop_category = seller.shop_category;
            sellerVM.party_category = seller.party_category;
            sellerVM.short_description = seller.short_description;
            sellerVM.user = seller.user;
            sellerVM.employees = [];
            if (fromServer) {
                _.map(seller.employees, employee => {
                    sellerVM.employees.push( {
                        _id: employee.user ? employee.user._id : employee._id,
                        name: employee.user ? employee.user.name : employee.name,
                        email: employee.user ? employee.user.email : employee.email,
                        permissions: _.map(SellerViewModel.all_permissions, permission => {
                            return {
                                label: permission.label,
                                value: _.indexOf(employee.permissions, permission.label) > -1 ? true : false,
                            };
                        })
                    });
                });
            } else {
                _.map(seller.employees, (employee) => {
                    sellerVM.employees.push(
                     {
                        user: employee._id,
                        permissions: _.chain(employee.permissions).filter('value').map('label').value()
                    });
                });
            }
            sellerVM.party_category = seller.party_category;
            sellerVM.primary_address = new AddressViewModel();
            sellerVM.primary_address.street =  seller.primary_address.street;
            sellerVM.primary_address.city =  seller.primary_address.city;
            sellerVM.primary_address.state =  seller.primary_address.state;
            sellerVM.primary_address.zip =  seller.primary_address.zip;
            sellerVM.primary_address.landmark =  seller.primary_address.landmark;
            sellerVM.location = seller.location;
            sellerVM.location = {
                type: 'Point',
                coordinates: [-90, 90]
            };

        } else {
            // Return a blank seller
            sellerVM.gstin = null;
            sellerVM.landline = null;
            sellerVM.licence = null;
            sellerVM.long_description = null;
            sellerVM.mobile = null;
            sellerVM.name = null;
            sellerVM.shop_category = null;
            sellerVM.short_description = null;
            sellerVM.user = null;
            sellerVM.party_category = null;
            sellerVM.employees = [];

            sellerVM.primary_address = new AddressViewModel();
            sellerVM.primary_address.street =  null;
            sellerVM.primary_address.city =  null;
            sellerVM.primary_address.state =  null;
            sellerVM.primary_address.zip =  null;
            sellerVM.primary_address.landmark =  null;
            sellerVM.location = {
                type: 'Point',
                coordinates: [-90, 90]
            };

        }
        return sellerVM;
    }

    public getSeller(): Seller {
        const seller: any = {
            _id: this._id,
            gstin: this.gstin,
            landline: this.landline,
            licence: this.licence,
            long_description: this.long_description,
            primary_address: this.primary_address,
            mobile: this.mobile,
            name: this.name,
            shop_category: this.shop_category,
            party_category: this.party_category,
            short_description: this.short_description,
            user: this.user,
            employees: this.employees,
            location: this.location,
        };
        return seller;
    }
}

export class AddressViewModel {
    public street: string;
    public city: string;
    public state: string;
    public zip: string;
    public landmark: string;

    getFullAddressString(): string {
        let fullAddress = '';
        if (this.street) {
            fullAddress += this.street + ',\n';
        }
        if (this.city) {
            fullAddress += this.city + ',';
        }
        if (this.state) {
            fullAddress += this.state + ',\n';
        }
        if (this.zip) {
            fullAddress += 'Pin - ' + this.zip;
        }

        return fullAddress;
    }
}
