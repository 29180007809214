import { Component, ElementRef, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Observable, Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';

export interface State {
    displayName: String;
    stateID: String;
}
export interface SearchParam {
    selSearchPartyId: String;
    selSearchPartyName: String;
    selSearchItemId: String;
    selSearchItemName: String;
    searchStartDate: Date;
    searchEndDate: Date;
    searchStatus: String;
}
@Component({
    selector: 'app-fsm-search',
    styleUrls: ['fsm-search.component.css'],
    templateUrl: 'fsm-search.component.html',
    providers: [
      // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
      // `MatMomentDateModule` in your applications root module. We provide it at the component level
      // here, due to limitations of our example generation script.
      { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
      { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
      { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    ],
})
export class FsmSearchComponent {

    @Input() states: State[];
    @Input() searchParam: SearchParam;
    @Input() showReturnButton = false;
    @Input() from_or_to: string = null;

    @Output() filter = new EventEmitter<SearchParam>();
    @Output() new = new EventEmitter();
    @Output() newReturn = new EventEmitter();
    private clickStream: Subject<SearchParam>;
    constructor() {
        this.clickStream = new Subject<SearchParam>();
        this.clickStream.pipe(throttleTime(500)).subscribe(search_param => this.filter.emit(search_param));

        //    var datePipe = new DatePipe();
        //    this.searchParam.searchDate= datePipe.transform(new Date(), 'yyyy-MM-dd');
    }

    public onInit() {
        //    this.clickStream.next(this.searchParam);
    }
    SearchPartySelected($event): void {
        this.searchParam.selSearchPartyName = $event.name;
        this.searchParam.selSearchPartyId = $event._id;
    }

    SearchItemSelected($event): void {
          this.searchParam.selSearchItemName = $event.name;
          this.searchParam.selSearchItemId = $event._id;

    }
    clearParty() {
      this.searchParam.selSearchPartyName = null;
      this.searchParam.selSearchPartyId = null;
    }
    clearItem() {
      this.searchParam.selSearchItemName = null;
      this.searchParam.selSearchItemId = null;

    }
    onFilter() {
        this.clickStream.next(this.searchParam);
    }
    onNew() {
        this.new.emit();
    }
    onNewReturn() {
        this.newReturn.emit();
    }
}
