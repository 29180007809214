import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './../common-service/auth.service';
import * as fsm from './order-fsm.js';
import * as _ from 'lodash';
import * as moment from 'moment';
import { UtilityService } from './../common-service/utility';
import { TranService } from './../transaction/tran.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private httpClient: HttpClient,
    private authService: AuthService,
    private tranService: TranService,
    private utility: UtilityService) {
    this.order_fsm = fsm;
    this.states = _.chain(fsm)
      .keys()
      .map(x => _.assign({}, { displayName: x.toUpperCase(), stateID: x }))
      .push({ displayName: 'ALL', stateID: 'all' })
      .value();
  }
  states;
  order_fsm;
  showProgessBar = true;
  searchParam: any = {
    selSearchPartyName: '',
    selSearchPartyId: null,
    selSearchItemName: '',
    selSearchItemId: null,
    // searchDate: new DatePipe('en-US').transform(new Date(), 'yyyy-MM-dd'),
    searchStartDate: moment().subtract(5, 'days').format('YYYY-MM-DD'),
    searchEndDate: moment().add(1, 'days').format('YYYY-MM-DD'),
    searchStatus: 'all'
  };
  errMsg: any;
  order: any = {
    to_: '',
    to_name: '',
    from_: '',
    from_name: '',
    item: '',
    item_name: '',
    quantity: null,
  };

  orders: any[] = [];
  idx = 0;
  fsm: {} = fsm;
  private pathPrefix = '/v1/manage/order/';
  getOrder(order_id): Observable<any> {
      const httpOptions = {
        'headers': new HttpHeaders({
          Authorization: this.authService.getToken()
        })
      };
      return this.httpClient.get(this.pathPrefix + 'list/order_by_from/' + order_id, httpOptions)
          .pipe(map(res => res['rows']));
  }

  getOrderList(filter: any, from_or_to: string): Observable<any> {
    const url = (from_or_to === 'from') ? 'list/order_by_from' : 'list/order_by_to';
    const params: any = {};
    if (filter.selSearchPartyId) {
        if (from_or_to === 'from') {
            params.to_ = filter.selSearchPartyId;
        } else {
            params.from_ = filter.selSearchPartyId;
        }
    }
    if (filter.selSearchItemId) {
        params.item = filter.selSearchItemId;
    }
    if (filter.searchStartDate) {
        params.orderdate_begin =  moment(filter.searchStartDate,
        ['YYYY-MM', 'YYYY-MM-DD', moment.ISO_8601]).startOf('day');
    }
         // filter.searchStartDate.setHours(0,0,0,0): null).getTime();
    params.orderdate_end = moment(filter.searchEndDate,
        ['YYYY-MM', 'YYYY-MM-DD', moment.ISO_8601]).endOf('day'); // filter.searchEndDate.setHours(23,59,59,999));
    if (filter.searchStatus === 'all') {
    } else {
        params.state_ = filter.searchStatus;
     }
    const httpOptions = {
      'headers': new HttpHeaders({
        Authorization: this.authService.getToken()
    }), 'params': params
    };
    return this.httpClient.get(this.pathPrefix + url, httpOptions)
      .pipe(map(res => this.extractOrderList(res)));
  }


  private extractOrderList(res: any) {
    const order_list = res.rows;
    order_list.map(order => {
      order.order_date = this.utility.get_date_obj(order.order_id);
      if (_.find(this.tranService.selectedOrders, ['order_id', order.order_id])) {
        order.selected = true;
      }

    });
    return order_list;
  }

  getPublishedOrdersBySupplier(supplier_id: string): Observable<any> {
      const httpOptions = {
        'headers': new HttpHeaders({
          Authorization: this.authService.getToken()
        })
      };
      return this.httpClient.get(this.pathPrefix + 'view/published_order_by_supplier/' + supplier_id, httpOptions)
          .pipe(map(res => this.extractPublishedOrdersBySupplier(res)));

  }

  private extractPublishedOrdersBySupplier(res: any) {
     const order_list = res;
      order_list.map(order => {
          order.order_date = this.utility.get_date_obj(order.order_id);
          if (_.find(this.tranService.selectedOrders , ['order_id', order.order_id])) {
              order.selected = true;
          }
      });
      return order_list;
  }

  getOrderHistories(order_id: string, from_or_to: string): Observable<any> {
      const url = (from_or_to === 'from') ? 'view/order_by_from/' : 'view/order_by_to/';
      const httpOptions = {
        'headers': new HttpHeaders({
          Authorization: this.authService.getToken()
        })
      };
      return this.httpClient.get(this.pathPrefix + url + order_id, httpOptions)
          .pipe(map(res => (res['rows'])));

  }

  createOrder(order: any, from_or_to: string): Observable<any> {
      const url = (from_or_to === 'from') ? 'insert/order_by_from' : 'insert/order_by_to';
      const httpOptions = {
        'headers': new HttpHeaders({
          Authorization: this.authService.getToken()
        })
      };
      return this.httpClient.post(this.pathPrefix + url, order, httpOptions)
          .pipe(map(res => res || {}));

  }

  updateOrder(order: any, key: string): Observable<any> {
      const httpOptions = {
        'headers': new HttpHeaders({
          Authorization: this.authService.getToken()
        })
      };
      return this.httpClient.post(this.pathPrefix + 'event/' + key, order, httpOptions)
          .pipe(map(res => res || {}));
  }

}
