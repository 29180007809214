import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SellerViewModel } from '../seller/seller-view-model';
import { Seller } from '../../models/seller.model';
import { AuthService } from './auth.service';
import { Observable, throwError } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { AuthData } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class SellerService {

  constructor(private authService: AuthService, private httpClient: HttpClient) { }


  // find(): Observable<Seller[]> {
  //     return this.authService.authData.pipe(switchMap<AuthData, any>((authData: AuthData) => {
  //         if (!authData) {
  //             return throwError({message: 'User Logged out'});
  //         }
  //         const httpOptions = {
  //             'headers': new HttpHeaders({
  //                 Authorization: authData.jwt
  //             })
  //         };
  //         return this.httpClient.get<Seller[]>('/v1/manage/seller/list', httpOptions);
  //     }));
  // }


  create(sellerVM: SellerViewModel): Observable<SellerViewModel> {

    return this.authService.authData.pipe(switchMap<AuthData, any>((authData: AuthData) => {
      if (!authData) {
        return throwError({ message: 'User Logged out' });
      }
      const httpOptions = {
        'headers': new HttpHeaders({
          Authorization: authData.jwt
        })
      };
      return this.httpClient.post<Seller>('/v1/manage/seller/create', sellerVM.getSeller(), httpOptions)
        .pipe(map<Seller, SellerViewModel>(res => SellerViewModel.create(res, true)));
    }));
  }

  update(sellerVM: SellerViewModel): Observable<SellerViewModel> {
      console.log(sellerVM);
    return this.authService.authData.pipe(switchMap<AuthData, any>((authData: AuthData) => {
      if (!authData) {
        return throwError({ message: 'User Logged out' });
      }
      const httpOptions = {
        'headers': new HttpHeaders({
          Authorization: authData.jwt
        })
      };
      return this.httpClient.post<Seller>('/v1/manage/seller/update', sellerVM.getSeller(), httpOptions)
        .pipe(map<Seller, SellerViewModel>(res => SellerViewModel.create(res, true)));
    }));
  }

  get(seller_id: string): Observable<SellerViewModel> {

    return this.authService.authData.pipe(switchMap<AuthData, any>((authData: AuthData) => {
      if (!authData) {
        return throwError({ message: 'User Logged out' });
      }
      const httpOptions = {
        'headers': new HttpHeaders({
          Authorization: authData.jwt
      }), params: { seller: seller_id}
      };
      return this.httpClient.get<Seller>('/v1/manage/seller/ownseller/get_seller', httpOptions)
        .pipe(map<Seller, SellerViewModel>(res => SellerViewModel.create(res, true)));
    }));
  }


       /*
    setCurrent(seller: any): Observable<any> {
        return this.authService.authData.pipe(switchMap<AuthData, any>((authData: AuthData) => {
            if (!authData) {
                return throwError({message: 'User Logged out'});
            }
            const httpOptions = {
                'headers': new HttpHeaders({
                    Authorization: authData.jwt
                }), 'params': {'seller': seller.user}
            };
            return this.httpClient.get('/v1/manage/seller/ownseller/set_current', httpOptions);
        }));
    }


    convertEmployeePermissions(seller) {
        if (seller.employees) {
            seller.employees = _.map(seller.employees, (employee) => {
                return {
                    user: employee.user._id,
                    permissions: _.chain(employee.permissions).filter('value').map('label').value()
                };
            });
        }
        return seller;
    }


    parse(res: Response) {

        seller.employees = _.map(seller.employees, (employee) => {
            return {
                user: employee.user,
                permissions: _.map(this.permissions, permission => {
                    return {
                        label: permission.label,
                        value: _.indexOf(employee.permissions, permission.label) > -1
                    }
                })
            }
        })
        return seller || {};
    }
  */
}
