import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from './../app-material-module';

import { CommonComponentsRoutingModule } from './common-components-routing.module';
import { ItemSearchComponent } from './item-search/item-search.component';
import { PartySearchComponent } from './party-search/party-search.component';
import { ConfirmActionComponent } from './confirm-action/confirm-action.component';
import { DialogDeleteComponent } from './dialog-delete/dialog-delete.component';
import { DialogAlertComponent } from './dialog-alert/dialog-alert.component';
import { FsmActionComponent } from './fsm-action/fsm-action.component';
import { FsmSearchComponent } from './fsm-search/fsm-search.component';
import { HistoryComponent } from './history/history.component';
import { ReportParam } from './report-param/report-param.component';
import { DebounceClickDirective } from './directives/debounce-click.directive';
import { SellerFormComponent } from './../seller/seller-form/seller-form.component';
import { PartyDialogComponent } from './../seller/party-dialog/party-dialog.component';
import { ItemBatchDialogComponent } from './../transaction/item-batch-dialog/item-batch-dialog.component';
import { ShowStockViewDialogComponent, StockViewDialogComponent } from './../transaction/stock-view/stock-view.component';
import { OrderDetailComponent } from './../order/detail/order-detail.component';
import { InvOrderComponent } from './../order/inv-order/inv-order.component';
import { OrderListDialogComponent } from './../order/order-list-dialog/order-list-dialog.component';
import { GoogleSignInComponent } from './../auth/google-sign-in/google-sign-in.component';
import { PricingComponent } from './../subscriptions/pricing/pricing.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import { SnackbarAlertComponent } from './snackbar-alert/snackbar-alert.component';
@NgModule({
  imports: [
    CommonModule,
    AppMaterialModule,
    CommonComponentsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
  ],
  exports: [
    ItemSearchComponent,
    PartySearchComponent,
    ConfirmActionComponent,
    DialogDeleteComponent,
    DialogAlertComponent,
    SnackbarAlertComponent,
    FsmActionComponent,
    FsmSearchComponent,
    ReportParam,
    HistoryComponent,
    DebounceClickDirective,
    SellerFormComponent,
    PartyDialogComponent,
    ItemBatchDialogComponent,
    ShowStockViewDialogComponent,
    OrderDetailComponent,
    InvOrderComponent,
    OrderListDialogComponent,
    GoogleSignInComponent,
    PricingComponent
  ],
  declarations: [
      ItemSearchComponent,
      PartySearchComponent,
      ConfirmActionComponent,
      DialogDeleteComponent,
      DialogAlertComponent,
      FsmActionComponent,
      FsmSearchComponent,
      ReportParam,
      HistoryComponent,
      DebounceClickDirective,
      SellerFormComponent,
      PartyDialogComponent,
      ItemBatchDialogComponent,
      ShowStockViewDialogComponent,
      StockViewDialogComponent,
      OrderDetailComponent,
      InvOrderComponent,
      OrderListDialogComponent,
      GoogleSignInComponent,
      PricingComponent,
      SnackbarAlertComponent
  ],
  entryComponents: [
      PartyDialogComponent,
      ItemBatchDialogComponent,
      StockViewDialogComponent,
      InvOrderComponent,
      OrderListDialogComponent,
      DialogDeleteComponent,
      DialogAlertComponent,
      SnackbarAlertComponent,
  ]
})
export class CommonComponentsModule { }
