import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';


@Component({
    selector: 'app-history',
    styleUrls: ['history.component.css'],
    templateUrl: 'history.component.html',
})
export class HistoryComponent implements OnInit {

    @Input() histories: any[];

    @Output() selectHistory = new EventEmitter<any>();
    selectedIdx = 0;
    constructor() {

    }

    ngOnInit() {
    }

    onSelectHistory(history, idx) {
        this.selectedIdx = idx;
        this.selectHistory.emit({'history': history, 'idx': idx});
    }

    setStyles(idx) {
        const styles = {
            // CSS property names

            'background-color': this.selectedIdx === idx ? '#cccccc' : 'white',
        //    'font-style': this.selectedIdx == idx ? 'italic' : 'normal',  // italic
        //    'font-weight': this.selectedIdx == idx ? 'bold' : 'normal',  // normal
        //    'font-size': this.selectedIdx == idx ? '24px' : '8px',     // 24px
        };
        return styles;
    }
}
