'use strict';

/* This will have the states as key and events as subobject */

module.exports = {
    'draft': {
        'editbyretailer': {
            'displayName': 'Edit by Buyer',
            'nextState': 'draft',
            'isServerOnly': false,
            'copyAttributes': ['item', 'item_name', 'quantity'],
            'checkPermission': (_obj, _jwt) => {
                return _obj.from_parent == _jwt.seller;
            }
        },
        'movetoboard': {
            'displayName': 'Publish',
            'nextState': 'board',
            'isServerOnly': false,
            'copyAttributes': [],
            'checkPermission': (_obj, _jwt) => {
                return _obj.from_parent == _jwt.seller;
            }
        },
        'deletebyretailer': {
            'displayName': 'Delete by Buyer',
            'nextState': 'deleted',
            'isServerOnly': false,
            'copyAttributes': [],
            'checkPermission': (_obj, _jwt) => {
                return _obj.from_parent == _jwt.seller;
            }
        }
    },
    'board': {
        'editbyretailer': {
            'displayName': 'Edit by Buyer',
            'nextState': 'board',
            'isServerOnly': false,
            'copyAttributes': ['item', 'item_name', 'quantity'],
            'checkPermission': (_obj, _jwt) => {
                return _obj.from_parent == _jwt.seller;
            }
        },
        'movetodraft': {
            'displayName': 'Unpublish',
            'nextState': 'draft',
            'isServerOnly': false,
            'copyAttributes': [],
            'checkPermission': (_obj, _jwt) => {
                return _obj.from_parent == _jwt.seller;
            }
        },
        'deletebyretailer': {
            'displayName': 'Delete by Buyer',
            'nextState': 'deleted',
            'isServerOnly': false,
            'copyAttributes': [],
            'checkPermission': (_obj, _jwt) => {
                return _obj.from_parent == _jwt.seller;
            }
        },
        'retailerselectsupplier': {
            'displayName': 'Select Supplier',
            'nextState': 'supplierapproval',
            'isServerOnly': false,
            //TODO: Special Action for find to_name and set in to_name and to_parent
            'copyAttributes': ['to_', 'to_name', 'to_parent'],
            'checkPermission': (_obj, _jwt) => {
                return _obj.from_parent == _jwt.seller;
            }
        },
        'supplierselectorder': {
            'displayName': 'Select Buyer',
            'nextState': 'retailerapproval',
            'isServerOnly': false,
            'copyAttributes': [],
            //TODO: Special action to set 'jwtseller' in 'to',
            'checkPermission': (_obj, _jwt) => {
                return _obj.to_parent == _jwt.seller;
            }
        }
    },
    'supplierapproval': {
        'editbyretailer': {
            'displayName': 'Edit by Buyer',
            'nextState': 'supplierapproval',
            'isServerOnly': false,
            'copyAttributes': ['item', 'item_name', 'quantity'],
            'checkPermission': (_obj, _jwt) => {
                return _obj.from_parent == _jwt.seller;
            }
        },
        'editbysupplier': {
            'displayName': 'Edit by Supplier',
            'nextState': 'retailerapproval',
            'isServerOnly': false,
            'copyAttributes': ['item', 'item_name', 'quantity'],
            'checkPermission': (_obj, _jwt) => {
                return _obj.to_parent == _jwt.seller;
            }
        },
        'acceptbysupplier': {
            'displayName': 'Accept by Supplier',
            'nextState': 'accepted',
            'isServerOnly': false,
            'copyAttributes': [],
            'checkPermission': (_obj, _jwt) => {
                return _obj.to_parent == _jwt.seller;
            }
        },
        'deletebysupplier': {
            'displayName': 'Delete by Supplier',
            'nextState': 'deleted',
            'isServerOnly': false,
            'copyAttributes': [],
            'checkPermission': (_obj, _jwt) => {
                return _obj.to_parent == _jwt.seller;
            }
        },
        'deletebyretailer': {
            'displayName': 'Delete by Buyer',
            'nextState': 'deleted',
            'isServerOnly': false,
            'copyAttributes': [],
            'checkPermission': (_obj, _jwt) => {
                return _obj.from_parent == _jwt.seller;
            }
        }
    },
    'retailerapproval': {
        'editbyretailer': {
            'displayName': 'Edit by Buyer',
            'nextState': 'supplierapproval',
            'isServerOnly': false,
            'copyAttributes': ['item', 'item_name', 'quantity'],
            'checkPermission': (_obj, _jwt) => {
                return _obj.from_parent == _jwt.seller;
            }
        },
        'editbysupplier': {
            'displayName': 'Edit by Supplier',
            'nextState': 'retailerapproval',
            'isServerOnly': false,
            'copyAttributes': ['item', 'item_name', 'quantity'],
            'checkPermission': (_obj, _jwt) => {
                return _obj.from_parent == _jwt.seller;
            }
        },
        'deletebysupplier': {
            'displayName': 'Delete by Supplier',
            'nextState': 'deleted',
            'isServerOnly': false,
            'copyAttributes': [],
            'checkPermission': (_obj, _jwt) => {
                return _obj.to_parent == _jwt.seller;
            }
        },
        'deletebyretailer': {
            'displayName': 'Delete by Buyer',
            'nextState': 'deleted',
            'isServerOnly': false,
            'copyAttributes': [],
            'checkPermission': (_obj, _jwt) => {
                return _obj.from_parent == _jwt.seller;
            }
        },
        'acceptbyretailer': {
            'displayName': 'Accept by Buyer',
            'nextState': 'accepted',
            'isServerOnly': false,
            'copyAttributes': [],
            'checkPermission': (_obj, _jwt) => {
                return _obj.from_parent == _jwt.seller;
            }
        }
    },
    'accepted': {
        'edit': {
            'displayName': 'Edit',
            'nextState': 'accepted',
            'isServerOnly': false,
            'copyAttributes': ['item', 'item_name', 'quantity'],
            'checkPermission': (_obj, _jwt) => {
                return _obj.from_parent == _jwt.seller;
            }
        },
        'delete': {
            'displayName': 'Delete',
            'nextState': 'deleted',
            'isServerOnly': false,
            'copyAttributes': [],
            'checkPermission': (_obj, _jwt) => {
                return _obj.to_parent == _jwt.seller;
            }
        },
        'transactioninitiate': {
            'displayName': null,
            'nextState': 'transactioninprogress',
            'isServerOnly': true,
            'copyAttributes': [],
            'checkPermission': (_obj, _jwt) => {
                return true;
            }
        }
    },
    'deleted': {

    },
    'transactioninprogress': {
        'transactioncomplete': {
            'displayName': null,
            'nextState': 'transactioncompleted',
            'isServerOnly': true,
            'copyAttributes': [],
            'checkPermission': (_obj, _jwt) => {
                return true;
            }
        }, 'transactiondelete': {
            'displayName': null,
            'nextState': 'accepted',
            'isServerOnly': true,
            'copyAttributes': [],
            'checkPermission': (_obj, _jwt) => {
                return true;
            }
        }
    },
    'transactioncompleted': {
    }
};
