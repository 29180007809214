import { Component,
         OnInit,
         OnChanges,
         SimpleChanges,
         Input,
         Output,
         EventEmitter } from '@angular/core';
import { SubscriptionService, Plan } from './../../common-service/subscription.service';
import { Observable, Subject } from 'rxjs';
import { throttleTime, debounceTime } from 'rxjs/operators';
import * as moment from 'moment';
@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit, OnChanges {

  @Input() currentPlan: any;
  @Input() nextPlan: any;
  @Input() inHomePage: boolean;
  @Output() selectPlan: any = new EventEmitter();

  private clickStream: Subject<string>;
  private vTableChangeStream: Subject<null>;

  public vTable;

  private newVTable() {
      return {
          'm-300': {
              'actionButton': null,
              'validUptoText': null,
              'startsFromText': null,
              'renewsOnText': null
          },
          'q-800' : {
              'actionButton': null,
              'validUptoText': null,
              'startsFromText': null,
              'renewsOnText': null
          },
          'y-3000': {
              'actionButton': null,
              'validUptoText': null,
              'startsFromText': null,
              'renewsOnText': null
          },
          'p-0': {
              'actionButton': null,
              'pauseText': null
          }
      };
  }

  constructor() {
      this.vTable = this.newVTable();
      this.clickStream = new Subject<string>();
      this.clickStream.pipe(throttleTime(500)).subscribe(plan => this.selectPlan.emit(plan));

      this.vTableChangeStream = new Subject<null>();
      this.vTableChangeStream.pipe(debounceTime(100)).subscribe(() => this.computeVTable());
  }

  ngOnInit() {
      this.vTableChangeStream.next(null);

  }

  ngOnChanges(changes: SimpleChanges) {
      if ('currentPlan' in changes || 'nextPlan' in changes) {
          this.vTableChangeStream.next(null);
      }
  }

  onSelectPlan(plan: string) {
      this.clickStream.next(plan);
  }

  private computeVTable() {
      this.vTable = this.newVTable();
      if (this.inHomePage) {
          for (const p in this.vTable) {
              if (this.vTable.hasOwnProperty(p)) {
                  this.vTable[p].actionButton = 'Start Today';
              }
          }
      } else {
          for (const p in this.vTable) {
              if (this.vTable.hasOwnProperty(p)) {
                  this.vTable[p].actionButton = 'Change Next Plan';
              }
          }
      }

      // cp-> plan == np-> plan; plan.showRenew
      // else { if cp-> plan endsOn, if nextPlan startOn
    if (this.currentPlan && this.nextPlan && this.currentPlan.plan === this.nextPlan.plan) {
        this.vTable[this.currentPlan.plan]['renewsOnText'] = `Renew On - ${moment(this.currentPlan.valid_till).format('ll')}`;
        this.vTable[this.currentPlan.plan]['actionButton'] = null;
    } else {
        if ( this.currentPlan ) {
            this.vTable[this.currentPlan.plan]['validUptoText'] = `Valid upto - ${moment(this.currentPlan.valid_till).format('ll')}`;
        }
        if ( this.nextPlan ) {
            this.vTable[this.nextPlan.plan]['startsFromText'] = `Effective from - ${moment(this.currentPlan.valid_till).format('ll')}`;
            this.vTable[this.nextPlan.plan]['actionButton'] = null;
        }
    }
  }
}
