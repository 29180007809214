import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [  {
        path: 'home',
        loadChildren: 'src/app/home/home.module#HomeModule'
    },
    {
            path: 'seller',
            loadChildren: 'src/app/seller/seller.module#SellerModule'
    },
    {
            path: 'transaction',
            loadChildren: 'src/app/transaction/transaction.module#TransactionModule'
    },
    {
            path: 'payment',
            loadChildren: 'src/app/payment/payment.module#PaymentModule'
    },
    {
            path: 'order',
            loadChildren: 'src/app/order/order.module#OrderModule'
    },
    {
            path: 'expiry',
            loadChildren: 'src/app/expiry/expiry.module#ExpiryModule'
    },
    {
            path: 'accounting',
            loadChildren: 'src/app/accounting/accounting.module#AccountingModule'
    },
    {
            path: 'report',
            loadChildren: 'src/app/report/report.module#ReportModule'
    },
    {
            path: 'subscriptions',
            loadChildren: 'src/app/subscriptions/subscriptions.module#SubscriptionsModule'
    },
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
    }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
