import {HttpClient} from '@angular/common/http';
import {Observable, Subject, of} from 'rxjs';
import { debounceTime, switchMap, distinctUntilChanged, map } from 'rxjs/operators';
import { Component, Input, Output, EventEmitter, ViewChild, OnInit, forwardRef, OnChanges, SimpleChange } from '@angular/core';
import {FormControl} from '@angular/forms';
import {PartyService} from './../../common-service/party.service';

@Component({
    selector: 'app-party-search',
    templateUrl: './party-search.component.html',
    styleUrls: ['./party-search.component.scss'],

})

export class PartySearchComponent implements OnInit, OnChanges {
    private party_url = '/v1/manage/party/list/';
    private searchTermStream = new Subject<string>();
    @Input() private selectedParty: any;
    @Input() private scope: string;
    @Input() private from_or_to: string;
    @Input() private isreturn: string;
    @Output() private selectParty = new EventEmitter();
    @Output() private selectTerm = new EventEmitter();
    @Input() enabled = true;
    constructor(private http: HttpClient, private partyService: PartyService) {

    }

    myControl = new FormControl();
    filteredOptions: Observable<any[]>;

    ngOnInit() {
        this.myControl.setValue(this.selectedParty);
        this.selectParty.emit(this.selectedParty);
        this.filteredOptions = this.myControl.valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            switchMap((term: string) => {
                if (term.length >= 1) {
                   this.selectTerm.emit(term);
                   return this.getParties(term);
                } else {
                   this.selectParty.emit(term);
                   return of([]);
                }
             })
         );
    }
    ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
        if (changes.selectedParty &&
            changes.selectedParty.previousValue &&
            changes.selectedParty.currentValue &&
            changes.selectedParty.currentValue._id !== changes.selectedParty.previousValue._id) {
            this.myControl.setValue(this.selectedParty);
        }
    }
    displayFn(party: any): string {
        this.selectParty.emit(party);
        return party ? party.name : party;
    }
    getParties(term: string) {
        this.displayFn({name: term});
        return this.partyService.getPartyList(term, this.scope, this.from_or_to, this.isreturn);
    }
}
