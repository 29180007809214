import { Component, ElementRef, EventEmitter, Input, Output, OnInit } from '@angular/core';
import {DatePipe} from '@angular/common';
import { Observable, Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';

export interface ReportParam {
    selReportPartyId: String;
    selReportPartyName: String;
    selReportItemId: String;
    selReportItemName: String;
    fromDate: Date;
    toDate: Date;
    reportId: String;
}
@Component({
    selector: 'app-report-param',
    styleUrls: ['report-param.component.css'],
    templateUrl: 'report-param.component.html',
    providers: [
      // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
      // `MatMomentDateModule` in your applications root module. We provide it at the component level
      // here, due to limitations of our example generation script.
      { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
      { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
      { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    ],
})

export class ReportParam {

    @Input() reportParam: ReportParam;
    @Input() scope: string;
    @Input() from_or_to: string;
    @Input() showPartySearch = false;
    @Input() showItemSearch = false;
    @Input() showStartDate = true;
    @Input() showEndDate = true;
    @Input()reports: any[] = [];
    @Output() filter = new EventEmitter<ReportParam>();

    private clickStream: Subject<ReportParam>;
    constructor() {
        this.clickStream = new Subject<ReportParam>();
        this.clickStream.pipe(throttleTime(500)).subscribe(report_param => this.filter.emit(report_param));

        //    var datePipe = new DatePipe();
        //    this.reportParam.reportDate= datePipe.transform(new Date(), 'yyyy-MM-dd');
    }

    public onInit() {
        //    this.clickStream.next(this.reportParam);
    }
    ReportPartySelected($event): void {
        this.reportParam.selReportPartyName = $event.name;
        this.reportParam.selReportPartyId = $event._id;
        console.log($event);
    }

    ReportItemSelected($event): void {
        if ($event._id === '') {
            this.reportParam.selReportItemName = '';
            this.reportParam.selReportItemId = null;
        } else {
            this.reportParam.selReportItemName = $event.name;
            this.reportParam.selReportItemId = $event._id;

        }
        console.log($event);
    }

    onFilter() {
        this.clickStream.next(this.reportParam);
    }

}
