import { Component, OnInit, Inject } from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material';

export interface SnackBarData {
  promt: string;
  alert_type: string;
}

@Component({
  selector: 'app-snackbar-alert',
  templateUrl: './snackbar-alert.component.html',
  styleUrls: ['./snackbar-alert.component.scss']
})
export class SnackbarAlertComponent implements OnInit {

  constructor(
      @Inject(MAT_SNACK_BAR_DATA) public data: SnackBarData) { }

  ngOnInit() {
  }

}
