import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { AppErrorHandlerService, AppError } from './common-service/app-error-handler.service';
import { MatSnackBar, MatSnackBarHorizontalPosition } from '@angular/material';
import { AuthService } from './common-service/auth.service';
import { SubscriptionService, Plan, PlanChangeRequest } from './common-service/subscription.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogAlertComponent } from './common-components/dialog-alert/dialog-alert.component';
import { SnackbarAlertComponent } from './common-components/snackbar-alert/snackbar-alert.component';
import * as moment from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'iron-snail-ng';
  currentPlan: Plan;
  currentPlanText: string;
  nextPlan: PlanChangeRequest;
  nextPlanText: string;
  pauseText: string = null;
  constructor(private appErrorHandlerService: AppErrorHandlerService,
    public router: Router,
    public authService: AuthService,
    public subscriptionService: SubscriptionService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar) {

  }

  ngOnInit() {
    //  if (!this.authService.loggedIn()) {
    //      return;
    //  }
    // // TODO: paymnet also to be checked. if any paymnet due show notification.
    // setTimeout(() => {
    //   if (!this.subscriptionService.isActive) {
    //     const snackBarRef = this.snackBar.open('Your subscription expired.', 'Renew', {
    //       horizontalPosition: 'center',
    //       verticalPosition: 'top',
    //     });
    //     snackBarRef.onAction().subscribe(() => {
    //       console.log('acction called');
    //       this.router.navigate(['/subscriptions/manage-subscriptions']);
    //     });
    //   } else {
    //     this.subscriptionService.getCurrentPlan().subscribe(plan => {
    //       this.currentPlan = plan;
    //       this.subscriptionService.getNextPlan().subscribe(nPlan => {
    //         this.nextPlan = nPlan;
    //         this.changeText();
    //       });
    //     });
    //   }
    // }, 1000);
  }
  changeText() {
    if (this.nextPlan.plan === 'p-0') {
      if (this.currentPlan !== null && this.nextPlan === null) {
        // hide pause button
        // WHen only the next plan is paused, but current plan is active
        this.pauseText = `Your subscription will pause from ${moment(this.currentPlan.valid_till).format('ll')}`;
      } else if (this.currentPlan === null && this.nextPlan === null) {
        // When current and next plan is paused
        this.pauseText = `Your subscription is currently paused.`;
      }
      const snackBarRef = this.snackBar.open(this.pauseText, 'Resume', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
      snackBarRef.onAction().subscribe(() => {
        this.router.navigate(['/subscriptions/manage-subscriptions']);
      });
    } else {
      if (this.currentPlan == null) {
        this.currentPlanText = 'You did not select any plan till now.';
      } else if (moment(this.currentPlan.valid_till).diff(moment(), 'days') < 0) {
        this.currentPlanText = `Your current plan ${this.currentPlan.plan},
             expired on ${moment(this.currentPlan.valid_till).format('ll')}`;
      } else if (moment(this.currentPlan.valid_till).diff(moment(), 'days') < 16) {
        this.currentPlanText = `Your current plan ${this.currentPlan.plan}
             will expire on ${moment(this.currentPlan.valid_till).format('ll')}`;
      }
      if (this.currentPlanText) {
        const snackBarRef = this.snackBar.open(this.currentPlanText, 'Renew', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
        snackBarRef.onAction().subscribe(() => {
          this.router.navigate(['/subscriptions/manage-subscriptions']);
        });
      }
    }

  }
  ngAfterViewInit() {
    this.appErrorHandlerService.subscribe({
      next: (error: AppError) => {
        if (error.category === 'user') {
          this.snackBar.open(error.message, 'Ok', {
            duration: error.duration || 1000,
          });
        }
        console.log(`${error.level} - ${error.category} - ${error.message}`);
      }, error: () => { }, complete: () => { }
    });

    this.authService.logInFromLocalStorage();
    this.authService.authData.subscribe({
      next: authData => {
        if (authData && !authData.seller) {
          this.router.navigate(['/seller/create']);
        }
      }
    });
  }
  newTran(from_or_to: string) {
    // this.tranService.transaction={
    //     transaction_json: {inv_type:null}
    // }
    this.router.navigate(['/transaction/tran-create', from_or_to, 'normal', 'no', { replaceUrl: true }]);
  }
  creatOldTran(from_or_to: string) {
    // this.tranService.transaction={
    //     transaction_json: {inv_type:null}
    // }
    // Bill of Supply
    this.router.navigate(['/transaction/tran-create', from_or_to, 'normal', 'yes', { replaceUrl: true }]);
  }
  creatOldReturnTran(from_or_to: string) {
    // this.tranService.transaction={
    //     transaction_json: {inv_type:'return'}
    // }
    // Bill of Supply
    this.router.navigate(['/transaction/tran-create', from_or_to, 'return', 'yes', { replaceUrl: true }]);

  }
  newTranReturn(from_or_to: string) {
      // this.tranService.transaction={
      //     transaction_json: {inv_type: 'return' }
      // }
      this.router.navigate(['/transaction/tran-create', from_or_to, 'return', 'no', {replaceUrl: true}]);
  }
}
