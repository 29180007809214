import {Component, OnInit, Inject} from '@angular/core';
import {Location} from '@angular/common';
import {Router, ActivatedRoute} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import * as _ from 'lodash';

@Component({
  selector: 'app-item-batch-dialog',
  templateUrl: './item-batch-dialog.component.html',
  styleUrls: ['./item-batch-dialog.component.scss']
})
export class ItemBatchDialogComponent implements OnInit {
  private orders: any;
  private from_or_to: string;
  constructor( private _location: Location,
      private activatedRoute: ActivatedRoute,
      private router: Router,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private mdDialogRef: MatDialogRef<ItemBatchDialogComponent>

  ) {
  }

  ngOnInit() {
      // //    this.orderService.create = true;
      // if (this.data) {
      //     this.from_or_to = this.data.from_or_to;
      //   } else {
      //     this.from_or_to = this.activatedRoute.snapshot.url[1].path;
      // };

  }

}
