import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../common-service/auth.service';
import { SellerService } from '../../common-service/seller.service';
import { Seller } from '../../../models/seller.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-auth-overlay',
  templateUrl: './auth-overlay.component.html',
  styleUrls: ['./auth-overlay.component.scss']
})
export class AuthOverlayComponent implements OnInit {

  public ownSellerList: Seller[];
  public employersList: Seller[];
  public userDisplayName: string;
  public profileImageUrl: string;
  public userEmail: string;

  constructor(private authService: AuthService,
                private sellerService: SellerService,
                private zone: NgZone,
                private router: Router
            ) {
    // this.authService.getOwnSellerList().subscribe((ownSellerList: Seller[]) => {
    //     this.ownSellerList = ownSellerList;
    //     console.log(ownSellerList);
    // });
    this.authService.getOwnSellerList().subscribe((ownSellerList: Seller[]) => {
        this.zone.run(() => {
            this.ownSellerList = ownSellerList;
            console.log(ownSellerList);
        });
    }, (error) => {
        this.ownSellerList = [];
        console.log(error.message);
    });
    this.authService.getOwnSellerList().subscribe((employersList: Seller[]) => {
        this.zone.run(() => {
            this.employersList = employersList;
        });
    }, (error) => {
        this.employersList = [];
        console.log(error.message);
    });
    this.authService.authData.subscribe((authData) => {
        this.zone.run(() => {
            if (authData) {
                this.userDisplayName = authData.name;
                this.profileImageUrl = authData.profileImageUrl;
                this.userEmail = authData.email;
            }
        });
    }, error => {
        console.log(error);
    });
  }

  switchUser() {
      this.authService.googleSignIn();
      // this.router.navigate(['/home']);
      // window.location.reload();
  }

  signOut() {
      this.authService.signOut();
  }
  createSeller() {
    this.router.navigate(['seller/create']);
  }
  updateSeller(seller_id: string, event) {
    event.stopPropagation();
    this.router.navigate(['seller/update', seller_id]);
  }

  setCurrentSeller(seller, event) {
      event.stopPropagation();
      this.authService.getSellerPermissions(seller._id)
      .subscribe(
          result => {
              this.authService.setCurrentSeller()
                  .subscribe(
                      res => {
                         // localStorage.setItem('id_token',  res.jwt);
                          console.log(this.authService.getDecodeToken());
                          this.router.navigate(['/home']);
                          window.location.reload();
                      },
                      error =>  console.log(error)
                  );
          },
          error =>  console.log(error)
      );
  }

  ngOnInit() {
  }

}
