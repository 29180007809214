import { Injectable, ErrorHandler,  } from '@angular/core';
import { Observable, Subject, Observer, Subscription} from 'rxjs';

export interface AppError {
    level: string;
    message: string;
    category: string;
    error?: any;
    source?: string;
    timestamp?: Date;
    duration?: number;
}

@Injectable({
  providedIn: 'root'
})
export class AppErrorHandlerService implements ErrorHandler {
    private errorSubject: Subject<AppError>;

    constructor() {
        this.errorSubject = new Subject<AppError>();

    }

    handleError(error: any) {
        this.errorSubject.next({
            level: 'DEBUG',
            message: error.message,
            category: 'uncaught',
            error: error,
        });
        throw error;
    }

    subscribe(observer: Observer<AppError>): Subscription  {
        return this.errorSubject.subscribe(observer);
    }

    publishError(error: AppError) {
        this.errorSubject.next(error);
    }
}
