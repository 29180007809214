import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Headers, RequestOptions } from '@angular/http';
import * as _ from 'lodash';
import {AuthService} from '../common-service/auth.service';
import { Observable, throwError } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { AuthData } from './auth.service';
import { Seller } from '../../models/seller.model';
import { SellerViewModel } from '../seller/seller-view-model';


@Injectable({
    providedIn: 'root'
})


export class PartyService {
    constructor(private httpClient: HttpClient, private authService: AuthService) {
    }
filteredOptions: Observable<any[]>;
getPartyList(term: string, scope: string, from_or_to: string, isreturn: string): Observable<SellerViewModel[]> {
    return this.authService.authData.pipe(switchMap<AuthData, any>((authData: AuthData) => {
      if (!authData) {
        return throwError({ message: 'User Logged out' });
      }
      const params: any = {search_text: term};
      if (scope) {
          params.scope = scope;
      }
      if (from_or_to) {
          params.from_or_to = from_or_to;
      }
      if (isreturn) {
          params.return = isreturn;
      }
      const httpOptions = {
        'headers': new HttpHeaders({
          Authorization: authData.jwt
      }), 'params' : params
      };
      return this.httpClient.get('/v1/manage/seller/listByName', httpOptions)
        .pipe(map(res => res || []));
    }));
}

get(_id: string): Observable<SellerViewModel> {

  return this.authService.authData.pipe(switchMap<AuthData, any>((authData: AuthData) => {
    if (!authData) {
      return throwError({ message: 'User Logged out' });
    }
    const httpOptions = {
      'headers': new HttpHeaders({
        Authorization: authData.jwt
    }), 'params': {'seller': _id}
    };
    return this.httpClient.get<Seller>('/v1/manage/seller/ownseller/get_seller', httpOptions)
      .pipe(map<Seller, SellerViewModel>(res => SellerViewModel.create(res, true)));
  }));
}

create(sellerVM: SellerViewModel): Observable<SellerViewModel> {
console.log(sellerVM);
  return this.authService.authData.pipe(switchMap<AuthData, any>((authData: AuthData) => {
    if (!authData) {
      return throwError({ message: 'User Logged out' });
    }
    const httpOptions = {
      'headers': new HttpHeaders({
        Authorization: authData.jwt
      })
    };

    // return this.httpClient.post<Seller>('/v1/manage/seller/create_party', sellerVM.getSeller(), httpOptions)
    //   .pipe(map<Seller, SellerViewModel>(SellerViewModel.create));
      return this.httpClient.post<Seller>('/v1/manage/seller/create_party', sellerVM.getSeller(), httpOptions)
         .pipe(map<Seller, SellerViewModel>(res => SellerViewModel.create(res['seller'], true)));
  }));
}

update(sellerVM: SellerViewModel): Observable<SellerViewModel> {
    console.log(sellerVM);
  return this.authService.authData.pipe(switchMap<AuthData, any>((authData: AuthData) => {
    if (!authData) {
      return throwError({ message: 'User Logged out' });
    }
    const httpOptions = {
      'headers': new HttpHeaders({
        Authorization: authData.jwt
      })
    };
    return this.httpClient.post<Seller>('/v1/manage/seller/update_party', sellerVM.getSeller(), httpOptions)
      .pipe(map<Seller, SellerViewModel>(res => SellerViewModel.create(res['seller'], true)));
  }));
}
    // createParty(party: any): Observable<any> {
    //     delete party.user
    //     //      party=this.convertEmployeePermissions(party);
    //     const body = JSON.stringify(party);
    //     const headers = new Headers({ 'Content-Type': 'application/json' });

    //     headers.append('Authorization', this.authService.getToken());
    //     const options = new RequestOptions({ headers: headers });
    //     return this.http.post('/v1/manage/seller/create_party', body, options)
    //         .map(this.extractparty)
    //         .catch(this.handleError);
    // }
    // updateParty(party: any): Observable<any> {
    //     //  console.log(party);
    //     const body = JSON.stringify(party);
    //     const headers = new Headers({ 'Content-Type': 'application/json' });

    //     headers.append('Authorization', this.authService.getToken());
    //     const options = new RequestOptions({ headers: headers });
    //     return this.http.post('/v1/manage/seller/update_party', body, options)
    //         .map(this.extractparty)
    //         .catch(this.handleError);
    // }

    // extractparty(res: Response) {
    //     const party = res.json();
    //     return party || {};
    // }

    // private extractData(res: Response) {
    //     const body = res.json();
    //     console.log(body);
    //     return body || {};
    // }

    // private handleError(err: any) {
    //     const error = err.json();

    //     const errMsg = (error.message) ? error.message :
    //         (error.status) ? error.status + ' ' + error.statusText : 'server.error';
    //     console.error(error);
    //     return Observable.throw(errMsg);

    // }


}
