import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Transaction } from './models/transaction.model';
import { Observable, timer, range, interval} from 'rxjs';
import { map, retryWhen, mergeMap, zip, switchMap } from 'rxjs/operators';
import {AuthService} from './../common-service/auth.service';
import * as fsm from './transaction-fsm.js';
import * as _ from 'lodash';
import * as moment from 'moment';
import {UtilityService} from './../common-service/utility';
import { SellerService } from './../common-service/seller.service';
import * as async from 'async';
import * as uuidv1 from 'uuid/v1';
import sha1 from 'crypto-js/sha1';


@Injectable({
  providedIn: 'root'
})
export class TranService {
    states: any;
    tran_fsm: any;
  constructor(private httpClient: HttpClient,
      private zone: NgZone,
      private authService: AuthService,
      private sellerService: SellerService,
      private utility: UtilityService) {
          this.tran_fsm = fsm;
          this.states = _.chain(fsm)
          .keys()
          .map(x => _.assign({}, { displayName: x.toUpperCase(), stateID: x }))
          .push({ displayName: 'ALL', stateID: 'all' })
          .value();
       }
       showProgessBar = true;
       searchParam: any = {
           selSearchPartyName: '',
           selSearchPartyId: null,
           selSearchItemName: '',
           selSearchItemId: null,
           // searchDate: new DatePipe('en-US').transform(new Date(), 'yyyy-MM-dd'),
          // searchStartDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
           searchStartDate: moment().subtract(1, 'days').startOf('day').format('YYYY-MM-DD'),
           searchEndDate: moment().add(1, 'days').format('YYYY-MM-DD'),
           searchStatus: 'all'
       };

       errMsg: any;

       trans: any[] = [];
       idx = 0;
       expirylist: any[] = [];
       selectedExpiries: any[] = [];
       private pathPrefix = '/v1/manage/transaction/';
       selectedOrders: any[] = [];
       transaction: any = {
           transaction_json: {}
       };
       total: any = {
           'netval': 0,
           'mrpval': 0,
           'taxableval': 0,
           'cgstval': 0,
           'sgstval': 0,
           'paidval': 0
       };
       rows: any = [];

       routechanged = false;

       generateScanId(): string {
           const scanId = uuidv1();
           const codeType = '2';
           const baseCode = sha1(scanId).toString().substring(0, 16).toUpperCase();
           const chksum = sha1(baseCode).toString().substring(0, 1).toUpperCase();
           return codeType + baseCode + chksum;
       }

       getScannedItems(scanId: string): Observable<any> {
           const path = `/v2/scanner/${scanId}/list`;
           return interval(2000).pipe(switchMap( _counter => this.httpClient.get(path)));
       }

       getFiteredTranList(searchParam, from_or_to: string) {
           this.showProgessBar = true;
               this.getTranList(searchParam, from_or_to).subscribe(
               trans => {
                   //            console.log(trans);
                   this.showProgessBar = false;
                   this.trans = trans;
                   this.total = {
                       'netval': 0,
                       'mrpval': 0,
                       'discval': 0,
                       'taxableval': 0,
                       'cgstval': 0,
                       'sgstval': 0,
                       'paidval': 0
                   };
                   _.map(trans, tran => {
                       if (tran.state_ !== 'deleted') {
                           if (tran.transaction_json.inv_type !== 'return') {
                               this.total.taxableval += (tran.transaction_json['ag_taxable_value'] || 0);
                               this.total.netval += (tran.total || 0);
                               this.total.cgstval += (tran.transaction_json['ag_cgst_amount'] || 0);
                               this.total.sgstval += (tran.transaction_json['ag_sgst_amount'] || 0);
                               if (tran.state_ === 'paid') {
                                   this.total.paidval += (tran.total || 0);
                               }
                           }
                   }});
               },
               errorMsg => {
                    this.errMsg = errorMsg;
                    this.showProgessBar = false;
                }
           );
       }

       getTranId(tran_id, from_or_to: string): Observable<any> {
           const url = (from_or_to === 'from') ? 'view/transaction_id_by_from/' : 'view/transaction_id_by_to/';
           const httpOptions = {
             'headers': new HttpHeaders({
               Authorization: this.authService.getToken()
             })
           };
           return this.httpClient.get(this.pathPrefix + url + tran_id, httpOptions)
               .pipe(map(res => res));
       }

       getTran(tran_id, from_or_to: string): Observable<any> {
           const url = (from_or_to === 'from') ? 'view/transaction_by_from/' : 'view/transaction_by_to/';
           const httpOptions = {
             'headers': new HttpHeaders({
               Authorization: this.authService.getToken()
             })
           };
           return this.httpClient.get(this.pathPrefix + url + tran_id, httpOptions)
               .pipe(map(res => res));
       }

       getBuyerDue(buyer_id): Observable<any> {
           const httpOptions = {
             'headers': new HttpHeaders({
               Authorization: this.authService.getToken()
             })
           };
           return this.httpClient.get(this.pathPrefix + 'view/buyer_due/' + buyer_id, httpOptions)
               .pipe(map(res => this.extractBuyerDue(res)));
       }

       getSupplierDue(supplier_id): Observable<any> {
           const httpOptions = {
             'headers': new HttpHeaders({
               Authorization: this.authService.getToken()
             })
           };
           return this.httpClient.get(this.pathPrefix + 'view/supplier_due/' + supplier_id, httpOptions)
               .pipe(map(res => this.extractBuyerDue(res)));
       }

       private extractBuyerDue(res: any) {
           const dues = res;
           if (dues.last_source_id) {
               dues.last_payment_date = this.utility.get_date_obj(dues.last_source_id).toLocaleDateString('en-in');
           }
           dues.total = (dues.total / 100);
           return dues;
       }
       createTran(transaction: Transaction, from_or_to: string): Observable<any> {
           const url = (from_or_to === 'from') ? 'insert/transaction_by_from' : 'insert/transaction_by_to';
           const httpOptions = {
             'headers': new HttpHeaders({
               Authorization: this.authService.getToken()
             })
           };
           const post_data = _.cloneDeep(transaction);
           post_data.total = (post_data.total) * 100;
           post_data.transaction_rule = JSON.stringify(post_data.transaction_rule);
           post_data.transaction_json.inv_prefix = post_data.transaction_json.inv_type === 'return' ? 'RTN' : 'INV';
           post_data.transaction_json = JSON.stringify(post_data.transaction_json);
           _.chain(post_data.items).map(item => {
               item.item_json.expiry = moment(item.item_json.expiry, 'YYYY-MM');
               item.item_json = JSON.stringify(item.item_json);
               return item;
           }).value();
           return this.httpClient.post(this.pathPrefix + url, post_data, httpOptions)
               .pipe(map(res => res || {}));
       }

       updateTran(transaction: any, key: string): Observable<any> {
           const httpOptions = {
             'headers': new HttpHeaders({
               Authorization: this.authService.getToken()
             })
           };
           const post_data = _.cloneDeep(transaction);
           post_data.total = (post_data.total).toFixed(0) * 100;
           post_data.transaction_rule = JSON.stringify(post_data.transaction_rule);
           post_data.transaction_json = JSON.stringify(post_data.transaction_json);
           _.chain(post_data.items).map(item => {
               item.item_json.expiry = moment(item.item_json.expiry, 'YYYY-MM');
               item.item_json = JSON.stringify(item.item_json);
               return item;
           }).value();
           return this.httpClient.post(this.pathPrefix + 'event/' + key, post_data, httpOptions)
               .pipe(map(res => res || {}));
       }
       getTranListFlat(filter: any, from_or_to: string): Observable<any> {

           const url = (from_or_to === 'from') ? 'list/transaction_by_from_flat' : 'list/transaction_by_to_flat';
           const params: any = {};
           if (filter.selSearchPartyId) {
               if (from_or_to === 'to') {
                   params.from_ = filter.selSearchPartyId;
               } else {
                   params.to_ = filter.selSearchPartyId;
               }
           }
           if (filter.selSearchItemId) {
               params.item = filter.selSearchItemId;
           }
           if (filter.searchStartDate) {
               params.trandate_begin =  moment(filter.searchStartDate,
               ['YYYY-MM', 'YYYY-MM-DD', moment.ISO_8601]).startOf('day');
           }
                // filter.searchStartDate.setHours(0,0,0,0): null).getTime();
           params.trandate_end = moment(filter.searchEndDate,
               ['YYYY-MM', 'YYYY-MM-DD', moment.ISO_8601]).endOf('day'); // filter.searchEndDate.setHours(23,59,59,999));
           if (filter.searchStatus === 'all') {
           } else {
               params.state_ = filter.searchStatus;
            }
           const httpOptions = {
            headers: new HttpHeaders({
                  Authorization: this.authService.getToken()
              }), params: params
           };
           return this.httpClient.get(this.pathPrefix + url, httpOptions)
               .pipe(map(res => this.extractTranList(res)));
       }

       getTranList(filter: any, from_or_to: string): Observable<any> {

           const url = (from_or_to === 'from') ? 'list/transaction_by_from' : 'list/transaction_by_to';
           const params: any = {};
           if (filter.selSearchPartyId) {
               if (from_or_to === 'to') {
                   params.from_ = filter.selSearchPartyId;
               } else {
                   params.to_ = filter.selSearchPartyId;
               }
           }
           if (filter.selSearchItemId) {
               params.item = filter.selSearchItemId;
           }
           if (filter.searchStartDate) {
               params.trandate_begin =  moment(filter.searchStartDate,
               ['YYYY-MM', 'YYYY-MM-DD', moment.ISO_8601]).startOf('day');
           }
                // filter.searchStartDate.setHours(0,0,0,0): null).getTime();
           params.trandate_end = moment(filter.searchEndDate,
               ['YYYY-MM', 'YYYY-MM-DD', moment.ISO_8601]).endOf('day'); // filter.searchEndDate.setHours(23,59,59,999));
           if (filter.searchStatus === 'all') {
           } else {
               params.state_ = filter.searchStatus;
            }
           const httpOptions = {
            headers: new HttpHeaders({
                  Authorization: this.authService.getToken()
              }), params: params
           };
           return this.httpClient.get(this.pathPrefix + url, httpOptions)
               .pipe(map(res => this.extractTranList(res)));
       }

       getReturnList(filter: any, from_or_to: string): Observable<any> {
           const url = (from_or_to === 'to') ? 'list/transaction_by_from' : 'list/transaction_by_to';
           const params: any = {};
           if (from_or_to === 'from') {
               params.from_ = filter.selSearchPartyId ? filter.selSearchPartyId : null;
           } else {
               params.to_ = filter.selSearchPartyId ? filter.selSearchPartyId : null;
           }
           // params.trandate_begin = filter.searchStartDate ? moment(filter.searchStartDate,
           //     ['YYYY-MM', 'YYYY-MM-DD', moment.ISO_8601]).startOf('day') : null;
                // filter.searchStartDate.setHours(0,0,0,0): null).getTime();
           params.trandate_end = moment(filter.searchEndDate,
               ['YYYY-MM', 'YYYY-MM-DD', moment.ISO_8601]).endOf('day'); // filter.searchEndDate.setHours(23,59,59,999));
           params.state_ = (filter.searchStatus === 'all') ? null : filter.searchStatus;
           const httpOptions = {
            headers: new HttpHeaders({
                  Authorization: this.authService.getToken()
              }), params: params
           };
           return this.httpClient.get(this.pathPrefix + url, httpOptions)
               .pipe(map(res => this.extractTranList(res)));
       }
       getTranHistories(tran_id: string, from_or_to: string): Observable<any> {
           const url = (from_or_to === 'from') ? 'view/transaction_by_from/' : 'view/transaction_by_to/';
           const httpOptions = {
             'headers': new HttpHeaders({
               Authorization: this.authService.getToken()
             })
           };
           return this.httpClient.get(this.pathPrefix + url + tran_id, httpOptions).pipe(
                   retryWhen(attempts => range(1, 20).pipe(
                   zip(attempts, i => i)).pipe(
                   mergeMap(i => {
                       return timer(i * 100);
                   }))),
               map(res => this.extractTranHistories(res)));

       }
       private extractTranHistories(res: any) {
           const tran_list = res;
           tran_list.map(tran => {
               tran.tran_date = this.utility.get_date_obj(tran.transaction_id).toLocaleDateString('en-in');
               tran.transaction_json = JSON.parse(tran.transaction_json);
               tran.transaction_rule = JSON.parse(tran.transaction_rule);
               tran.total = (tran.total / 100);
               tran.items.forEach(item => {
                   item.item_json = JSON.parse(item.item_json);
                   item.item_json.expiry = item.item_json.expiry && moment(item.item_json.expiry,
                       ['YYYY-MM', 'YYYY-MM-DD', moment.ISO_8601]).format('YYYY-MM');
                   item.item_json_keys = [];
               });

               return tran;
           });
           return tran_list;
       }
       private extractTranList(res: any) {
           const tran_list = res;
           tran_list.map(tran => {
               tran.tran_date = this.utility.get_date_obj(tran.transaction_id).toLocaleDateString('en-in');
               tran.sortable_tran_date = this.utility.get_date_obj(tran.transaction_id).valueOf();
               tran.transaction_json = JSON.parse(tran.transaction_json);
               tran.total = (tran.total / 100);
           });
           //    return _.uniqBy(_.reverse(tran_list),n => n.transaction_id);
           return tran_list;
       }

       getStock(item_id): Observable<any> {
           const httpOptions = {
             'headers': new HttpHeaders({
               Authorization: this.authService.getToken()
             })
           };
           return this.httpClient.get(this.pathPrefix + 'view/current_stock_by_item/' + item_id, httpOptions)
               .pipe(map(res => res));
       }

       getSupplierByItemList(filter: any): Observable<any> {
           const url = 'list/transaction_suppliers_by_item';
           const params: any = {};
           params.item = filter.selSearchItemId;
           params.trandate_end = new Date();
           const httpOptions = {
            'headers': new HttpHeaders({
                  Authorization: this.authService.getToken()
              }), 'params': params
           };
           return this.httpClient.get(this.pathPrefix + url, httpOptions)
               .pipe(map(res => this.extractTranList(res)));
       }
       getSuppliersForBatch(item: string, batch_: string): Observable<any> {
           const httpOptions = {
             'headers': new HttpHeaders({
               Authorization: this.authService.getToken()
             })
           };

           const post_data = {
               item: item,
               batch_: batch_
           };
           return this.httpClient.post(this.pathPrefix + 'list/suppliers_by_item_batch', post_data, httpOptions)
               .pipe(map(res => this.extractSupplierList(res)));
       }
       private extractSupplierList(res: any) {
           const tran_list = res;
           tran_list.map(tran => {
               tran.from_ = tran.from_;
               tran.from_name = tran.from_name;
           });
           //    return _.uniqBy(_.reverse(tran_list),n => n.transaction_id);
           return tran_list;
       }
       getTransactionsForBatch(item: string, batch_: string): Observable<any> {
           const httpOptions = {
             'headers': new HttpHeaders({
               Authorization: this.authService.getToken()
             })
           };

           const post_data = {
               item: item,
               batch_: batch_
           };
           return this.httpClient.post(this.pathPrefix + 'list/transactions_by_batch', post_data, httpOptions)
               .pipe(map(res => this.extractTransactionsForBatch(res)));
       }

       private extractTransactionsForBatch(res: any) {
           const purchases = res.purchases;
           purchases.map(purchase => {
               purchase.tran_date = this.utility.get_date_obj(purchase.transaction_id).toLocaleDateString('en-in');
               purchase.item_json = JSON.parse(purchase.item_json);
               purchase.transaction_json = JSON.parse(purchase.transaction_json);
           });
           const sales = res.sales;
           sales.map(sale => {
               sale.tran_date = this.utility.get_date_obj(sale.transaction_id).toLocaleDateString('en-in');
               sale.item_json = JSON.parse(sale.item_json);
               sale.transaction_json = JSON.parse(sale.transaction_json);
           });
           return {purchases: purchases, sales: sales};

       }

       getExpiryList(): Observable<any> {
           const httpOptions = {
             'headers': new HttpHeaders({
               Authorization: this.authService.getToken()
             })
           };
           return this.httpClient.get(this.pathPrefix + 'view/current_expiry_list', httpOptions)
               .pipe(map(res => this.extractExpiry(res)));
       }

       private extractExpiry(res: any) {
           const expiry_list = res;
           if (expiry_list !== 'No Expiry') {
           expiry_list.map(expiry => {
               expiry.item.expiry_json = JSON.parse(expiry.item.expiry_json);
               if (expiry.item.expiry_json) {
                   expiry.item_name = expiry.item.expiry_json.ptran.item_name;
                   expiry.from_name = expiry.item.expiry_json.ptran.from_name;
                   expiry.item.expiry_json.ptran.item_json = JSON.parse(expiry.item.expiry_json.ptran.item_json);
               }
           });
           return _.sortBy(expiry_list, [function (el) {
                       return el.item.expiry_json ? el.item.expiry_json.ptran.from_name : ' ';
                   }, function (el) {
                       return el.item.expiry_date;
               }]);
           } else {
            return expiry_list;
           }
       }


       public getItemJsonByBatch(item_id: string, batch_: string) {
           const httpOptions = {
             'headers': new HttpHeaders({
               Authorization: this.authService.getToken()
             })
           };
           return this.httpClient.get(this.pathPrefix + `view/current_item_json_by_batch/${item_id}/${batch_}`, httpOptions);
       }

       public parseExpiry(expiry: string): string {
           let exp = 'Jan-2017';

           exp = moment(expiry,
           ['YYYY-MM', 'YYYY-MM-DD', 'MM/YYYY', 'DD/MM/YYYY', 'MM/DD/YYYY', 'MM/YY', 'DD/MM/YY', 'MM/DD/YY',
            moment.ISO_8601]).format('YYYY-MM');

           if (exp === 'Invalid date') {
               exp = 'Jan-2017';
           }
           return exp;
       }


}
