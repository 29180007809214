import {Component, Input, Output, OnInit, OnChanges, SimpleChange} from '@angular/core';
import {FormControl} from '@angular/forms';
import {TranService} from './../../transaction/tran.service';
import { Observable, Subject} from 'rxjs';
import { throttleTime, startWith, map} from 'rxjs/operators';
import {AuthService} from './../../common-service/auth.service';

@Component({
    selector: 'app-order-detail',
    styleUrls: ['./order-detail.component.scss'],
    templateUrl: './order-detail.component.html'
})

export class OrderDetailComponent implements OnChanges, OnInit {

    @Input() order: any;
    @Input() from_or_to: string;
    errMsg: any;
    myControl = new FormControl();
    options: any[] = [];
    filteredOptions: Observable<any[]>;
    called = false;
    private clickStream: Subject<any>;
    constructor(public tranService: TranService,
                public authService: AuthService) {
      this.clickStream = new Subject<any>();
      this.clickStream.pipe(throttleTime(500)).subscribe( item => this.getSupplersForItem(item));
    }

    ngOnInit() {
        this.filteredOptions = this.myControl.valueChanges.pipe(
            startWith(null),
            map(user => user && typeof user === 'object' ? user.name : user),
            map(name => name ? this.filter(name) : this.options.slice()));
    }

    ngOnChanges(changes: {[propKey: string]: SimpleChange}) {
      const log: string[] = [];
      for (const propName of Object.keys(changes)) {
          const changedProp = changes[propName];
      }
    }

    itemSelected($event): void {
        this.order.item = $event._id;
        this.order.item_name = $event.name;
        this.clickStream.next($event._id);
      //  this.getSupplersForItem($event._id);
    }

    partySelected($event): void {
        if (this.from_or_to === 'from') {
            this.order.to_ = $event._id;
            this.order.to_name = $event.name;
        } else {
            this.order.from_ = $event._id;
            this.order.from_name = $event.name;
        }
    }
    getSupplersForItem(item: any) {

        if (item) {
            this.options = [];
            this.options.length = 0;
            this.tranService.getSupplierByItemList({ selSearchItemId: item }).subscribe(
                trans => {
                    trans.map(tran => this.options.push({ name: tran.from_name, _id: tran.from_ }));
                    // if (trans[0] && (this.from_or_to == 'from' && this.order.to_ == '')
                    //  || (this.from_or_to=='to' && this.order.from_ == ''))
                    //     this.partySelected({ name: trans[0].from_name, _id: trans[0].from_ });
                    //  this.order.to_ = trans[0] ? trans[0].from_ : null;
                    //  this.order.to_name = trans[0] ? trans[0].from_name : null;
                    this.order.quantity = trans[0] ? JSON.parse(trans[0].item_json).pack_qty : 1;
                },
                errorMsg => this.errMsg = errorMsg
            );

        }
    }

    filter(name: string): any[] {
        return this.options.filter(option => new RegExp(`^${name}`, 'gi').test(option.name));
    }
    onChange(event) {
        this.partySelected(event);
    }
    onFocus() {

    }
    selectListItem(event) {
        this.partySelected(event);
    }
    displayFn(supplier: any): string {
        return supplier ? supplier.name : supplier;
    }
}
