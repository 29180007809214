import { Component, OnInit, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { AuthService } from '../../common-service/auth.service';
import { AppErrorHandlerService } from '../../common-service/app-error-handler.service';
// declare var gapi: any;

@Component({
  selector: 'app-google-sign-in',
  templateUrl: './google-sign-in.component.html',
  styleUrls: ['./google-sign-in.component.css']
})
export class GoogleSignInComponent implements OnInit,  AfterViewInit {
  googleLoginButtonId = 'google-login-button';
  @ViewChild('signinbtn') signInBtn: ElementRef;

  constructor(public matIconRegistry: MatIconRegistry,
              private authService: AuthService,
              private appErrorHandlerService: AppErrorHandlerService) {

      matIconRegistry.registerFontClassAlias('font-awesome', 'fa');
  }

  ngOnInit() {
      this.authService.isGoogleUserChanged().subscribe((data) => {
       console.log(data);
      }, (err) => {
            this.appErrorHandlerService.publishError({
                level: 'ERROR',
                message: err.error,
                category: 'http',
                error: err
            });
      });
  }

  ngAfterViewInit() {

  }

  onClick() {
    this.authService.googleSignIn();
  }

}
