import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-auth-profile-button',
  templateUrl: './auth-profile-button.component.html',
  styleUrls: ['./auth-profile-button.component.scss']
})
export class AuthProfileButtonComponent implements OnInit {

  @Input('userName') userName: string;
  @Input()
  set currentSellerName(currentSellerName: string) {
      this._currentSellerName = currentSellerName ? '@ ' + currentSellerName : '';
  }
  get currentSellerName(): string {
      return this._currentSellerName;
  }

  private _currentSellerName: string;
  constructor() {

  }

  ngOnInit() {
  }

}
