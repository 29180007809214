import { Injectable } from '@angular/core';
import { AuthService, AuthData } from './auth.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';


export interface Plan {
  seller: string;
  id: string;
  plan: string;
  valid_till: Date;
  extra_json: string;
}

export interface PlanChangeRequest {
  seller: string;
  id: string;
  plan: string;
  extra_json: string;
}

export interface Payment {
  seller: string;
  id: string;
  category: string;
  extra_json: string;
  amount: number;
}

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  isActive: boolean;
  isActive$: BehaviorSubject<boolean>;

  constructor(private httpClient: HttpClient, private authService: AuthService) {
    this.isActive$ = new BehaviorSubject<boolean>(false);
    this.isActive$.subscribe(isActive => {
      this.isActive = isActive;
    });
    this.checkIsActive();
  }


  insertNextPlan(plan: string) {
    return this.authService.authData.pipe(switchMap<AuthData, any>((authData: AuthData) => {
      if (!authData) {
        return throwError({ message: 'User Logged out' });
      }
      const httpOptions = {
        'headers': new HttpHeaders({
          Authorization: authData.jwt
        })
      };
      return this.httpClient.post<any>('/v1/manage/subscription/update-next-plan', { plan: plan }, httpOptions);
    }));

  }

  getCurrentPlan() {
    return this.authService.authData.pipe(switchMap<AuthData, any>((authData: AuthData) => {
      if (!authData) {
        return throwError({ message: 'User Logged out' });
      }
      const httpOptions = {
        'headers': new HttpHeaders({
          Authorization: authData.jwt
        })
      };
      return this.httpClient.get<any>('/v1/manage/subscription/get-current-plan', httpOptions);
    }));
  }

  getNextPlan() {
    return this.authService.authData.pipe(switchMap<AuthData, any>((authData: AuthData) => {
      if (!authData) {
        return throwError({ message: 'User Logged out' });
      }
      const httpOptions = {
        'headers': new HttpHeaders({
          Authorization: authData.jwt
        })
      };
      return this.httpClient.get<any>('/v1/manage/subscription/get-next-plan', httpOptions);
    }));
  }

  getPayments() {
    return this.authService.authData.pipe(switchMap<AuthData, any>((authData: AuthData) => {
      if (!authData) {
        return throwError({ message: 'User Logged out' });
      }
      const httpOptions = {
        'headers': new HttpHeaders({
          Authorization: authData.jwt
        })
      };
      return this.httpClient.get<any>('/v1/manage/subscription/get-payment-list', httpOptions);
    }));
  }

  checkIsActive() {
    this.authService.authData.pipe(switchMap<AuthData, any>((authData: AuthData) => {
      if (!authData) {
        return throwError({ message: 'User Logged out' });
      }
      const httpOptions = {
        'headers': new HttpHeaders({
          Authorization: authData.jwt
        })
      };
      return this.httpClient.get<any>('/v1/manage/subscription/is-active', httpOptions);
    })).subscribe(res => {
      if (res.isActive === true) {
        this.isActive$.next(true);
      } else {
        this.isActive$.next(false);
      }
    });
  }
}
