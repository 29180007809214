import { Component, ElementRef, EventEmitter, Input, Output, OnInit, OnChanges, SimpleChange } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
// import * as fsm from './../../../../app/plugins/order/order-fsm.js';
import * as _ from 'lodash';
import { AuthService } from './../../common-service/auth.service';


export interface Action {
    displayName: String;
    actionID: String;
}

@Component({
    selector: 'app-fsm-action',
    styleUrls: ['fsm-action.component.css'],
    templateUrl: 'fsm-action.component.html',
})
export class FsmActionComponent implements OnInit, OnChanges {

    actions: Action[];
    @Input() order: any;
    @Input() fsm: any;
    @Output() confirm = new EventEmitter<Action>();
    @Output() cancel = new EventEmitter<string>();
    @Output() selectAction = new EventEmitter<boolean>();
    @Input() selectedValue: any = null;
    private clickStream: Subject<Action>;
    constructor(private authService: AuthService) {
    //    console.log(_.keys(fsm));
        this.clickStream = new Subject<Action>();
        this.clickStream.pipe(throttleTime(5000)).subscribe( action => this.confirm.emit(action));
    }
    ngOnInit() {
        this.actions = this.fillActions(this.order);
        this.selectedValue = null;
    }
    fillActions (order: any) {
        return _.chain(this.fsm[order.state_])
        .transform((result, value, key) => {
            if (value.displayName) {
                result.push({'displayName': value.displayName, 'actionID': key, checkPermission: value.checkPermission});
            }
        }, [])
        .filter(x => x.checkPermission(order, this.authService.getDecodeToken()))
        .value();
    }
    ngOnChanges(changes: {[propKey: string]: SimpleChange}) {
    //    console.log(this.order);
        this.actions = this.fillActions(this.order);
    }
    onConfirm(action) {
        this.clickStream.next(action);
    }
    onChangeActionSelection(event) {
        this.selectAction.emit(event);
    }
    onCancel() {
        this.selectedValue = null;
        this.selectAction.emit(null);
    }
}
